import '../scss/app.scss'
// esm-bundlers includes the compiler, the compiler is needed for in-DOM templates or templates via inline JavaScript strings
// See: https://v3.vuejs.org/guide/installation.html#explanation-of-different-builds
import { createApp } from 'vue/dist/vue.esm-bundler'
import Carousel from './components/Carousel.vue'
import AlbumCarousel from './components/AlbumCarousel.vue'
import StoriesLevelCarousel from './components/StoriesLevelCarousel.vue'

const Root = {
  delimiters: ['[[', ']]'],
}
const app = createApp(Root)

app.component('general-carousel', Carousel)
app.component('album-carousel', AlbumCarousel)
app.component('stories-carousel', StoriesLevelCarousel)
app.config.globalProperties.window = window
app.mount('#vueApp')

// On window load add the class load to the body
window.addEventListener('load', () => {
  document.body.classList.add('loaded')
})
